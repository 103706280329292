
    import {Component, Prop, Vue} from "vue-property-decorator";
    import {UserModule} from "@/store/modules/user";
    import {getOu} from "@/utils/cookies";
    import {AppModule} from "@/store/modules/app";

    @Component({name:'ImportExcel'})
    export default class ImportExcel extends Vue{

        @Prop({required:false,default:'模板上传'})
        title!:string;

        @Prop({required:true})
        url!:string;

        form ={
            show:false,
            submitting: false,
            data: {
                Year: "",
                Month: "",
                Target: 0
            },
            rules: {}
        };


        get uploadHeaders(){
            return  {
                "Abp.OrganizationUnitId": UserModule.ouId || getOu(),
                "Abp.TenantId": AppModule.getTenantId,
                "Abp.ManagePlatform":true,
                "Authorization" :`Bearer ${UserModule.getToken}`
            }
        }

        uploadFile() {
            this.form.show = true;
        }

        handleRemove() {}

        submitUpload() {
            (this.$refs.upload as any).submit();
        }

        handleUploadSuccess(res:any) {
            if (res.result.importResults && res.result.importResults.length > 0) {
                this.jumpDetail(res.result.importResults);
                this.form.show = false;
            }
        }

        handleUploadError(res:any) {
            const json = JSON.parse(res.message);
            this.$message({
                type: "error",
                message: `上传失败!${json.error.message}`
            });
            this.form.show = false;
        }
        jumpDetail(table:any) {
            this.$router.push({
                name: "uploadTemplateDetail",
                params: {
                    table: table
                }
            });
        }
    }
