
  import {Component, Prop, Vue} from "vue-property-decorator";
  import axios from "axios";
  import {UserModule} from "@/store/modules/user";
  import {AppModule} from "@/store/modules/app";
  import {getOu} from "@/utils/cookies";
  @Component({
    name:'ExportButton'
  })
  export default class ExportButton extends Vue {


    @Prop({required:true})
    queryModel!:object;

    @Prop({required:true})
    url!:string;
    @Prop({required:true})
    fileTitle!:string;

    loading:boolean =false;

    handleExport(){
      this.loading = true;
      axios(this.url,{
          method:'POST',
          baseURL:process.env.VUE_APP_BASE_API,
          data:this.queryModel,
          responseType:'blob',
          headers:{
              'Authorization':`Bearer ${UserModule.getToken}`,
              '.AspNetCore.Culture':'c=zh-Hans|uic=zh-Hans',
              'Abp.ManagePlatform':true,
              'Abp.OrganizationUnitId':UserModule.ouId || getOu(),
              'Abp.TenantId':AppModule.getTenantId,
              'Content-Type':'application/json'
          },

      }).then(res=>{
        if(!res){
          this.loading = false;
          return
        }
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', `${this.fileTitle}.xls`);

        document.body.appendChild(link);
        setTimeout(()=>{
          link.click();
          this.loading = false;
        },300);


      });

    }


  }
